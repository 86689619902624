
	import { defineComponent, reactive, onMounted, toRefs } from "vue";

	import router from "../router";
	import http from "../../../../common/services/http";
	import store from "../../../../common/services/store";

	export default defineComponent({
		setup() {
			const settings = reactive({
				forex: {
					rate: {
						dollar: 109,
						euro: 132,
						pound: 151,
					},
				},
				wages: {
					rate: 0,
				},
				finance: {
					interest: 0,
					tonnage: 0,
				},
				reg: {
					allow: "no",
				},
			});

			const { user } = toRefs(store.state);

			function updateSettings() {
				http.post("/api/settings", settings).then((res: any) => {
					settings.forex = res.data.forex;
					settings.finance = res.data.finance;
					settings.wages = res.data.wages;
					settings.reg = res.data.reg;
				});
			}

			onMounted(() => {
				http.get(`/api/settings`).then((res: any) => {
					if (res.data.reg) {
						settings.forex = res.data.forex
							? res.data.forex
							: { rate: { dollar: 109, euro: 132, pound: 151 } };
						settings.finance = res.data.finance
							? res.data.finance
							: { interest: 0, tonnage: 0 };
						settings.wages = res.data.wages
							? res.data.wages
							: { rate: 0 };
						settings.reg = res.data.reg
							? res.data.reg
							: { allow: "no" };
					}
				});
			});

			return {
				user,
				settings,
				updateSettings,
			};
		},
	});
